import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './logos/fulllogo_nobuffer.jpg';
import './App.css';
import Footer from './components/FooterNew.js';
import SliderComponent from './components/Slider';
import About from './components/About.js';
import GetInvolved from './components/GetInvolved.js';
import ContactUs from './components/ContactUs.js';

import ronaldProfile from '../src/assets/img/ronald.jpeg';
import woodrowProfile from '../src/assets/img/woodrow.jpeg';
import franklinProfile from '../src/assets/img/frank.jpeg';
import bushProfile from '../src/assets/img/bush.jpeg';
import bidenProfile from '../src/assets/img/binden.jpeg';
import harryProfile from '../src/assets/img/harry.jpeg';
import billProfile from '../src/assets/img/bill.jpeg';
import council from './assets/img/council.png';
import SlideShow from './components/SlideShow.js';
import ThreeBarIcon from './components/ThreeBarIcon.js';

const cards = [
  {
    image: bushProfile,
    title: "George Bush Jr.",
    biography: "The United States must defend liberty and justice because these principles are right and true for all people everywhere. No nation owns these aspirations, and no nation is exempt from them. Fathers and mothers in all societies want their children to be educated and to live free from poverty and violence. No people on earth yearn to be oppressed, aspire to servitude, or eagerly await the midnight knock of the secret police.",
    designation: "US President"
  },
  {
    image: ronaldProfile,
    title: "Ronald Reagan",
    biography: "The United States must be a beacon for democracy. Unfortunately, many in the world are prevented from seeing our beacon. For many more, it has been distorted; and still others, who are able to see it and are inspired by it, need help in the form of practical assistance.",
    designation: "US President"
  },
  {
    image: harryProfile,
    title: "Harry S. Truman",
    biography: "Democracy alone can supply the vitalizing force to stir the peoples of the world into triumphant action, not only against their human oppressors, but also against their ancient enemies—hunger, misery, and despair.",
    designation: "US President"
  },
  {
    image: franklinProfile,
    title: "Franklin D. Roosevelt",
    biography: "We have learned, that we cannot live alone at peace. We have learned that our own well being is dependent on the well being of other nations far away. We have learned to be citizens of the world, members of the human community.",
    designation: "US President"
  },
  {
    image: billProfile,
    title: "Bill Clinton",
    biography: "[F]ree elections [alone] are not enough. Genuine, lasting democracy also requires respect for human rights, including the right to political dissent; freedom of religion and belief; an independent media capable of engaging an informed citizenry; a robust civil society; the rule of law and an independent judiciary; open and competitive economic structures; mechanisms to safeguard minorities from oppressive rule by the majority; full respect for women’s and workers’ rights; and civilian control of the military.",
    designation: "US President"
  },
  {
    image: bidenProfile,
    title: "Joe Biden",
    biography: "I firmly believe that democracy holds the key to freedom, prosperity, peace, and dignity. We must now demonstrate — with a clarity that dispels any doubt — that democracy can still deliver for our people and for people around the world. We must prove that our model isn’t a relic of history; it’s the single best way to realize the promise of our future. And, if we work together with our democratic partners, with strength and confidence, we will meet every challenge and outpace every challenger.",
    designation: "US President"
  },
  {
    image: woodrowProfile,
    title: "Woodrow Wilson",
    biography: "No peace can last, or ought to last, which does not recognize and accept the principle that governments derive all their just powers from the consent of the governed, and that no right anywhere exists to hand peoples about from sovereignty to sovereignty as if they were property.",
    designation: "US President"
  }
];

function App() {
  return (
    <Router>
      <div>
        {/* The top header which has logo on left div, while slides on right div */}
        
        <div id="threebars">
          <ThreeBarIcon/>
        </div>
         
        <div id="top-header">
          {/* left div */}
          <div id="logo-div">
            <a href="/">
              <img src={logo} alt="logo" className="logo-image" />
            </a>
          </div>

          <div id="trending-div"> 
            <SlideShow/>
          </div>
        </div>

        <div id="topnav">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/visionaries">Visionaries</Link>
          <Link to="/world_const">World Constitution</Link>
          <Link to="/contactus">Contact Us</Link>
          <Link to="/donation" className="split">Support Us</Link>
        </div>

        <div id="body-div"> 
          <Routes>
            <Route path="/" element={
              <div id="home">
                <div id="top-img">
                  <div className="__top_img-full-width">
                    <img src={council} alt="council_image" />
                    <div className="__top_img-overlay-button">Read More</div>
                    <div className="__top_img-overlay">
                      <p>The Constitutional Order Institute aims to revolutionize theory and practice of world politics by adopting medical approach to disease. Indeed, for the institute, state qualifies as a living organism which is prone to conflict (disease) and is curable through constitution (medicine). Medical professionals are aware that road to health passes through the study of “cell”. Likewise, the founder believes, that road to peace passes through the study of “market”. Curiously, the Institute derives laws—specifically constitution and international law—from market only to help citizens and policymakers materialize peace and liberty worldwide.</p>
                    </div>
                  </div>
                </div>

      
                <h1><span> What Political Leaders Say</span></h1>
                <SliderComponent cards={cards} slidesToShow={3} />

                <h1><span> Our Core Values</span></h1>
                <div className="objectives-list">
                  <ul>
                    <li><b>Integrity:</b> Commitment to honesty, transparency, and ethical conduct in all our actions.</li>
                    <li><b>Respect:</b> Upholding the dignity and rights of every individual, as guaranteed by constitutional norms.</li>
                    <li><b>Unity:</b> Fostering a sense of unity through shared values and common goals.</li>
                    <li><b>Empowerment:</b> Educating and empowering citizens to actively participate in democratic processes.</li>
                  </ul>
                </div>

                
              </div>
            } />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/about" element={<About />} />
            <Route path="/get_involved" element={<GetInvolved />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
