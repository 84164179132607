import React from 'react';
//import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Zoom } from 'react-slideshow-image';
import './SlideShow.css';
// const indicators = (index) => (<div className="indicator">{index + 1}</div>);

const SlideShow = () => {
    // const images = [
    //     "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    //     "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
    //     "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    // ];

    return (
        // <Slide>
            // <Zoom indicators={indicators} scale={2.5}>
            <Zoom  scale={2.5}>
            <div className="each-slide-effect">
                    <h1>Advancing liberty.</h1>
                    <br>
                    </br>
                    <span>Rousseau's works, especially "The Social Contract," argue that true liberty is found in obeying laws that one has a hand in making. For him, advancing liberty means creating a society where individuals participate directly in their own governance and are thus truly free.</span>
                
            </div>
            <div className="each-slide-effect">
            <h1>Welcome to the world of peace</h1>
                    <br>
                    </br>
                    <span>Man is born free, and everywhere he is in chains.</span>
               
            </div>
            <div className="each-slide-effect">
                    <h1>Reshaping democracy. Advancing liberty.</h1>
                    <br>
                    </br>
                    <span>Rousseau emphasized the concept of popular sovereignty and the importance of direct democracy. He believed that legitimate political authority rests on the consent of the governed and that the general will of the people should guide the state.</span>
            </div>
        </Zoom>
        //  </Slide> 
    );
};

export default SlideShow;